<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="480px">
      <v-card>
        <v-card-title
          >Select Student
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle class="pa-2">Input: {{ input }}</v-card-subtitle>
        <v-divider></v-divider>
        <v-text-field
          dense
          class="pa-1 pt-2"
          v-model="search"
          label="Search"
          outlined
          placeholder="Search by name"
        ></v-text-field>

        <v-card-text style="height: 300px">
          <v-list>
            <v-list-item-title v-if="exactMatch && exactMatch.id"
              >Exact Match</v-list-item-title
            >
            <v-list-item
              v-if="exactMatch && exactMatch.id"
              :class="
                exactMatch.id == selectedStudent.id
                  ? 'selected-student-list '
                  : 'student-list '
              "
              @click="selectStudent(exactMatch)"
            >
              <v-list-item-avatar class="pr-6">
                <AAvatar
                  v-if="getAvatar(exactMatch)"
                  :width="40"
                  :height="40"
                  :avatar="getAvatar(exactMatch).avatar"
                />
                <v-img
                  v-else
                  :max-height="40"
                  :max-width="40"
                  :src="require('@/assets/CS0040_default_F0.png')"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-left">{{
                  exactMatch.name
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="exactMatch.mochiId">
                    {{ exactMatch.mochiId }}</span
                  ><span v-if="exactMatch.mochiId && exactMatch.Classroom">
                    |
                  </span>
                  <span v-if="exactMatch.Classroom">{{
                    exactMatch.Classroom.name
                  }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              class="my-2"
              v-if="exactMatch && exactMatch.id"
            ></v-divider>
            <v-list-item-title v-if="students.length != 0"
              >Suggestion</v-list-item-title
            >
            <v-list-item
              v-for="(student, i) in filteredSuggestStudents"
              :key="i + '_student'"
              :class="
                student.id == selectedStudent.id
                  ? 'selected-student-list '
                  : 'student-list '
              "
              @click="selectStudent(student)"
            >
              <v-list-item-avatar class="pr-6">
                <AAvatar
                  v-if="getAvatar(student)"
                  :width="40"
                  :height="40"
                  :avatar="getAvatar(student).avatar"
                />
                <v-img
                  v-else
                  :max-height="40"
                  :max-width="40"
                  :src="require('@/assets/CS0040_default_F0.png')"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-left">{{
                  student.name
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="student.mochiId"> {{ student.mochiId }}</span>
                  <span v-if="student.mochiId && student.Classroom"> | </span>
                  <span v-if="student.Classroom">{{
                    student.Classroom.name
                  }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="student-list"
              v-if="filteredSuggestStudents.length == 0 && students.length != 0"
            >
              <v-list-item-content>
                <div class="text-center">No Student</div>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="students.length != 0" class="my-2"></v-divider>
            <v-list-item-title>All Students</v-list-item-title>

            <v-list-item
              v-for="(student, i) in filteredAllStudents"
              :key="i"
              :class="
                student.id == selectedStudent.id
                  ? 'selected-student-list '
                  : 'student-list '
              "
              @click="selectStudent(student)"
            >
              <v-list-item-avatar class="pr-6">
                <AAvatar
                  v-if="getAvatar(student)"
                  :width="40"
                  :height="40"
                  :avatar="getAvatar(student).avatar"
                />
                <v-img
                  v-else
                  :max-height="40"
                  :max-width="40"
                  :src="require('@/assets/CS0040_default_F0.png')"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-left">{{
                  student.name
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="student.mochiId"> {{ student.mochiId }}</span>
                  <span v-if="student.mochiId && student.Classroom"> | </span>
                  <span v-if="student.Classroom">{{
                    student.Classroom.name
                  }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="student-list"
              v-if="filteredAllStudents.length == 0"
            >
              <v-list-item-content>
                <div class="text-center">No Student</div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["students", "exactMatch", "selectedName", "input", "callbackSelect"],
  data() {
    return {
      dialogm1: "",
      dialog: false,
      selectedStudent: {},
      search: "",
      //BOC:[api]
      api: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      playerApi: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      //EOC
      allstudents: [],
      players: [],
    };
  },
  computed: {
    filteredAllStudents() {
      const searchTerm = this.search.toLowerCase().trim();

      return this.$_.differenceBy(this.allstudents, this.students, "id").filter(
        (student) => student.name.toLowerCase().includes(searchTerm)
      );
    },
    filteredSuggestStudents() {
      const searchTerm = this.search.toLowerCase().trim();
      var exactMatchStudent = [];
      if (this.exactMatch) {
        exactMatchStudent.push(this.exactMatch);
      }
      return this.$_.differenceBy(
        this.students,
        exactMatchStudent,
        "id"
      ).filter((student) => student.name.toLowerCase().includes(searchTerm));
    },
  },
  created() {
    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.parentId +
      "/classroom/" +
      this.$route.params.id +
      "/student/lastYear";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.allstudents = resp;
      this.playerApi.params = {
        mochiIds: this.$_.compact(this.$_.map(this.allstudents, "mochiId")),
      };
      this.$api.fetch(this.playerApi);
    };
    this.playerApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/backend/player/getByMochiIds";

    this.playerApi.callbackReset = () => {
      this.playerApi.isLoading = true;
      this.playerApi.isError = false;
    };

    this.playerApi.callbackError = (e) => {
      this.playerApi.isLoading = false;
      this.playerApi.isError = true;
      this.playerApi.error = e;
    };

    this.playerApi.callbackSuccess = (resp) => {
      this.playerApi.isLoading = false;
      this.api.isLoading = false;
      this.players = resp;
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    openDialog() {
      this.selectedStudent = {};
      this.search = "";
      this.selectedStudent = this.selectedName;
      this.dialog = true;
    },
    selectStudent(student) {
      this.selectedStudent = student;
      this.callbackSelect(student);
    },
    getAvatar(user) {
      let avatar = null;
      if (user.mochiId) {
        avatar = this.$_.find(this.players, function (n) {
          if (n.mochiId == user.mochiId) {
            return n.avatar;
          }
        });
      }

      return avatar;
    },
  },
};
</script>
<style>
.student-list {
  border: 1px solid black;
  margin: 3px;
}
.selected-student-list {
  border: 2px solid blue;
  margin: 3px;
}
</style>