<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Add Student(s)</v-card-title>
      <v-stepper elevation="0" :value="step" v-if="step < 6">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="1 < step">Input</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="2 < step">Check</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="3 < step">Done</v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <div class="d-flex align-center justify-space-around">
        <div v-if="step == 1" class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <TextareaField
              :seletedStudents="students"
              :callbackSelect="getStudents"
            >
            </TextareaField>
          </v-form>
        </div>

        <div v-else-if="step == 2" class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <Preview
              @close="back"
              @back="back"
              :students="students"
              :callbackSelectedStudent="setStudents"
            />
          </v-form>
        </div>
      </div>
      <div class="d-flex align-center justify-space-around">
        <div v-if="step == 1" class="button-content text-center">
          <div class="text-center pt-1">
            <v-btn width="75%" rounded color="primary" dark @click="next()">
              Next
            </v-btn>
          </div>
          <div class="text-center pt-2">
            <v-btn
              width="75%"
              rounded
              outlined
              color="primary"
              dark
              @click="$router.go(-1)"
            >
              Cancel
            </v-btn>
          </div>
        </div>
        <!-- <div v-else-if="step == 2" class="button-content text-center">
          <div class="text-center pt-1">
            <v-btn width="75%" :loading="api.isLoading" rounded color="primary" @click="submit()" :disabled="students.length < 1">
              Submit
            </v-btn>
          </div>
          <div class="text-center pt-2">
            <v-btn
              width="75%"
              rounded
              outlined
              color="primary"
              dark
              @click="back()"
            >
              Back
            </v-btn>
          </div>
        </div> -->
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import Preview from "@/components/Educator/Student/ImportPreview";
import { mapState } from "vuex";
import TextareaField from '@/components/Educator/Student/TextareaFieldStudent.vue';
export default {
  components: {
    Preview,
    TextareaField,
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    step: 1,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    name: null,
    students:[],
    

     //BOC:[api]
     classroomApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.parentId },
        },
        exact: true,
      },
      {
        text: "Classrooms",
        to: {
          name: "PageEducatorInstitutionClassroom",
          params: { id: this.$route.params.parentId },
        },
        exact: true,
      },
      {
        text: "Classroom",
        to: { name: "PageEducatorInstitutionClassroomDashboard" },
        exact: true,
      },
      {
        text: "Students",
        to: { name: "PageEducatorInstitutionClassroomStudent" },
        exact: true,
      },
      {
        text: "New Student",
        to: { name: "PageEducatorInstitutionClassroomStudentImport" },
        exact: false,
      },
    ];
    //EOC
    this.classroomApi.method = "GET";
    this.classroomApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
       "/educator/institution/"+this.$route.params.parentId+"/classroom/" +
      this.$route.params.id;

    this.classroomApi.callbackReset = () => {
      this.classroomApi.isLoading = true;
      this.classroomApi.isError = false;
    };

    this.classroomApi.callbackError = (e) => {
      this.classroomApi.isLoading = false;
      this.classroomApi.isError = true;
      this.classroomApi.error = e;
    };

    this.classroomApi.callbackSuccess = (resp) => {
      this.classroomApi.isLoading = false;
      // this.updateBreadcrumb(resp)
      return resp;
    }
  },
  mounted() {
    this.$api.fetch(this.classroomApi);
  },
  methods: {
    // updateBreadcrumb(resp) {
    //   this.breadcrumbs.push({
    //     text: "Dashboard",
    //     to: { name: "PageEducatorDashboard" },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: resp.Institution.name,
    //     to: { name: "PageEducatorInstitutionDashboard",params:{id:this.$route.params.parentId} },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: 'Classrooms',
    //     to: { name: "PageEducatorInstitutionClassroom",params:{id:this.$route.params.parentId} },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: resp.name,
    //     to: { name: "PageEducatorInstitutionClassroomDashboard" ,params:{id:this.$route.params.id,parentId:this.$route.params.parentId}},
    //     exact: true,
    //   });

    //   this.breadcrumbs.push({
    //   text: "Students",
    //   to: { name: "PageEducatorInstitutionClassroomStudent" ,params:{id:this.$route.params.id,parentId:this.$route.params.parentId} },
    //   exact: true,
    // });
    // this.breadcrumbs.push({
    //   text: "New Student",
    //   to: { name: "PageEducatorInstitutionClassroomStudentAdd" },
    //   exact: true,
    // });
    // },
    close() {
      this.$router.push({ name: 'PageEducatorInstitutionClassroomStudent', params: { parentId:  this.$route.params.parentId,id:  this.$route.params.id } });
    },
   
    next() {
      if(this.students.length > 1 && this.students[this.students.length-1] == "") this.students.pop();
      this.step++;
    },
    back() {
      this.step--;
    },
    getStudents(val) {
      this.students = val;
      for (var i = 0; i < this.students.length; i++) {
        if (this.students[i].name == "") {
          this.students.splice(i, 1);
          i--
        }
      }
    },
    setStudents(students){
      this.students = students
      this.back()
    }
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
.button-content {
  width: 300px !important;
}
</style>