<template>
  <div>
    <div style="min-height: 265px">
      <div v-if="allstudents.length > 0">
        <div class="text-left">
          Total {{ allstudents.length }} Students (
          {{ $_.filter(allstudents, { isNew: true }).length }} New,
          {{ $_.filter(allstudents, { isNew: false }).length }} Old)
        </div>
        <div v-for="(item, i) in allstudents" :key="i" class="pa-1">
          <v-card class="mx-auto" outlined>
            <div class="d-flex">
              <div class="pl-2 pt-2">{{ i + 1 }}. {{ item.name }}</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="showDeleteDialog(i)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div v-if="classroom.schoolYear != 1" class="d-flex">
              <v-switch
                hide-details
                class="pl-2 py-0 my-0"
                v-model="item.isNew"
                :label="item.isNew ? 'New Student' : 'Old Student'"
                @change="getName(item)"
              ></v-switch>

              <div class="pl-2 grey--text text--darken-2" v-if="item.selectedName.Classroom && !item.isNew">
                From {{ item.selectedName.Classroom.name }}
              </div>
            </div>
            <div class="pa-2">
              <v-textarea
                label="Name"
                auto-grow
                outlined
                rows="1"
                hide-details
                v-model="item.selectedName.name"
                :readonly="!item.isNew"
                @click="item.isNew ? null : showDialog(item)"
              ></v-textarea>
              <div class="d-flex align-center">
                <v-spacer></v-spacer>
                <!-- <v-chip style="top: -10" v-if="!item.verified && item.suggestedNames[0]" x-small>
                  Suggested
                </v-chip> -->
                <div>
                  <span
                    v-if="item.verified"
                    class="mdi mdi-check-circle green--text text-h5"
                  ></span>
                  <span
                    v-else
                    @click="verify(item)"
                    style="cursor: pointer"
                    class="mdi mdi-alert orange--text text-h5"
                  ></span>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <div v-else-if="api.isLoading">
        <ALoader :isLoading="api.isLoading"></ALoader>
      </div>
      <div v-else>No names are found.</div>
    </div>
    <div class="d-flex align-center justify-space-around">
      <div class="button-content text-center">
        <div class="text-center pt-1">
          <v-btn
            width="75%"
            :loading="saveApi.isLoading"
            rounded
            color="primary"
            @click="submit()"
            :disabled="
              allstudents.length < 1 ||
              $_.some(allstudents, { verified: false })
            "
          >
            Submit
          </v-btn>
        </div>
        <div class="text-center pt-2">
          <v-btn
            width="75%"
            rounded
            outlined
            color="primary"
            dark
            @click="back()"
          >
            Back
          </v-btn>
        </div>
      </div>
    </div>
    <StudentSelect
      :students="selectedStudent.suggestedNames"
      :exactMatch="selectedStudent.exactMatch"
      :selectedName="selectedStudent.selectedName"
      :input="selectedStudent.name"
      ref="select_student"
      :callbackSelect="selectStduent"
    ></StudentSelect>
    <DeleteConfirmationDialog
      ref="delete_student"
      :callbackDelete="remove"
    ></DeleteConfirmationDialog>
  </div>
</template>

<script>
import DeleteConfirmationDialog from "./DeleteConfirmationDialog.vue";
import StudentSelect from "./StudentSelect.vue";
export default {
  components: { StudentSelect, DeleteConfirmationDialog },
  props: ["callbackSuccess", "students", "callbackSelectedStudent"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    saveApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    allstudents: [],
    newStudents: [],
    oldStudentsId: [],
    type: [
      { name: "New", key: "new" },
      { name: "Old", key: "old" },
    ],
    selectedStudent: [],
    classroom: {},
    deleteKey: null,
  }),
  computed: {
    // combinedText(student) {
    //   return () => {
    //     var name = null;
    //     if (student.selectedName.Classroom && student.selectedName.Classroom.name) {
    //       name = `${student.selectedName.name} - ${student.selectedName.Classroom.name}`;
    //     } else {
    //       name = `${student.selectedName.name}`;
    //     }
    //     return name;
    //   };
    // },
  },
  created() {
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.parentId +
      "/classroom/" +
      this.$route.params.id +
      "/student/suggest";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.allstudents = resp.Student;
      this.classroom = resp.Classroom;
      if(this.$_.every(this.allstudents, { verified: false })){
        this.allstudents = this.$_.map(this.allstudents, (student) => {
        return { ...student, verified: true };
    });
      }
    };

    this.saveApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.parentId +
      "/classroom/" +
      this.$route.params.id +
      "/student/import";
    this.saveApi.callbackReset = () => {
      this.saveApi.isLoading = true;
      this.saveApi.isError = false;
      this.saveApi.error = null;
    };
    this.saveApi.callbackError = (e) => {
      this.saveApi.isLoading = false;
      this.saveApi.isError = true;
      this.saveApi.error = e;
    };
    this.saveApi.callbackSuccess = () => {
      this.saveApi.isLoading = false;
      this.$router.push({
        name: "PageEducatorInstitutionClassroomStudent",
        params: {
          parentId: this.$route.params.parentId,
          id: this.$route.params.id,
        },
      });
    };
  },
  mounted() {
    this.api.params = this.$_.clone({ names: this.students });
    this.$api.fetch(this.api);
  },
  methods: {
    // combinedText(student) {
    //   console.log(student)
    //   var name = null
    //   if(student.selectedName.Classroom && student.selectedName.Classroom.name){
    //     name = `${student.selectedName.name} - ${student.selectedName.Classroom.name}`
    //   }else{
    //    name =  `${student.selectedName.name}`
    //   }
    //   return name;
    // },
    showDeleteDialog(key) {
      this.deleteKey = key;
      var that = this;
      setTimeout(() => {
        that.$refs["delete_student"].openDialog();
      }, 100);
    },
    remove() {
      this.students.splice(this.deleteKey, 1);
      this.allstudents.splice(this.deleteKey, 1);
      // this.api.params = this.$_.clone({ names: this.students });
      //  this.$api.fetch(this.api);
      if (this.students.length == 0) {
        this.$emit("close");
      }
    },
    selectStduent(student) {
      this.selectedStudent.verified = true;
      this.selectedStudent.selectedName = student;
    },
    cancel() {
      this.$emit("close");
    },
    back() {
      this.callbackSelectedStudent(this.allstudents);
      //this.$emit("back");
    },
    verify(student) {
      student.verified = true;
    },
    showDialog(student) {
      this.selectedStudent = student;
      var that = this;
      setTimeout(() => {
        that.$refs["select_student"].openDialog();
      }, 100);
    },
    submit() {
      this.newStudents = this.$_.chain(this.allstudents)
        .filter({ isNew: true })
        .map("selectedName.name")
        .value();

      const filteredData = this.$_.filter(
        this.allstudents,
        (item) => item.isNew == false
      );
      this.oldStudentsId = this.$_.map(filteredData, "selectedName.id");

      this.saveApi.params = this.$_.clone({
        newStudents: this.newStudents,
        oldStudents: this.oldStudentsId,
      });
      this.$api.fetch(this.saveApi);
    },
    getName(item) {
      if (item.isNew) {
        item.oldName = this.$_.cloneDeep(item.selectedName)
        item.selectedName.name = item.name;
        item.verified = true;
      } else {
        if(item.oldName){
          item.selectedName.name = item.oldName.name;
        }
        item.verified = false;
      }
    },

    setName: (item) => `${item.name} (${item.Classroom.name})`,
  },
};
</script>

<style>
.button-content {
  width: 300px !important;
}
</style>